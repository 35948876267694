import React from "react";
import icon from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-sm navbar-dark"
      style={{ backgroundColor: "#121117", padding: "20px" }}
    >
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img
            src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/02/WMT_Horizontal_White.png"
            alt="NFT's"
            width="150px"
            height="auto"
          />
        </Link>
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div id="navbarNav" className="collapse navbar-collapse">
          <ul
            style={{
              fontSize: "0.8rem",
              display: "flex",
              alignItems: "center",
            }}
            className="navbar-nav ml-auto align-middle text-light"
          >
            <li className="nav-item mr-0">
              <Link to="/" className="nav-link  text-light text-capitalize">
                Home
              </Link>
            </li>
            <li className="nav-item mr-0">
              <Link
                to="/marketplace"
                className="nav-link  text-capitalize text-light"
              >
                Marketplace
              </Link>
            </li>
            <li className="nav-item mr-2">
              <Link to="/mint" className="nav-link text-capitalize text-light">
                Mint NFT
              </Link>
            </li>
            {/* <li className="nav-item mr-3">
              <a href="#" className="cursor-pointer h5 text-light">
                <i className="fas fa-search"></i>
              </a>
            </li> */}
            <li className="nav-item">
              <Link
                to="/wallet"
                className="btn btn-bordered-white btn-Home text-light .fw-7 text-capitalize navBtn"
              >
                <i className="fas fa-wallet"></i>&nbsp;Wallet Connect
              </Link>
            </li>
            {/*<li className="nav-item">
              <Link to="/my-tokens" className="nav-link">
                My Tokens
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/queries" className="nav-link">
                Queries
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                Contact US
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link btn-Main">
                wallet connect
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
