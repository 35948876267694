import React from "react";
import { Link } from "react-router-dom";
import CryptoBoyNFTDetails from "../CryptoBoyNFTDetails/CryptoBoyNFTDetails";
const AccountDetails = ({ accountAddress, accountBalance, cryptoBoys }) => {
  return (
    // <div>
    //   <div className="jumbotron" style={{ marginBottom: "0px" }}>
    //     <h1 className="display-5">CryptoBoy NFT Marketplace</h1>
    //     <p className="lead">
    //       This is an NFT marketplace where you can mint ERC721 implemented{" "}
    //       <i>Crypto Boy NFTs</i> and manage them.
    //     </p>
    //     <hr className="my-4" />
    //     <p className="lead">Account address :</p>
    //     <h4 className="text-break">{accountAddress}</h4>
    //     <p className="lead">Account balance :</p>
    //     <h4>{accountBalance} Ξ</h4>
    //   </div>
    // </div>
    <div className="mt-3">
      <div className="intro">
        <span className="font-weight-bold h5 iconMain">WebMobTech</span>
      </div>
      <div className="row py-5">
        <div className="col-12 col-sm-6">
          <p className="display-4 font-weight-bold text-light">
            Discover, collect, and sell extraordinary NFTs
          </p>
          <p className="lead">
            Explore on the world's first & largest NFT marketplace
          </p>
          <div className="d-flex mt-4">
            <Link
              to="/marketplace"
              className="btn btn-bordered-white text-light font-weight-bold mr-3 text-capitalize btn-Home"
            >
              <i className="fas fa-rocket"></i>&nbsp;Explore
            </Link>
            <Link
              to="/mint"
              className="btn btn-bordered-white btn-Home text-light .fw-7 text-capitalize"
            >
              <i className="far fa-plus-square"></i>&nbsp;Create
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="intro">
          <span className="font-weight-bold h5 iconMain">Auctions</span>
        </div>
        <div className="">
          <h2 className="text-light text-capitalize mt-3 mb-4">
            Live Auctions
          </h2>
        </div>
        {/* <div className="auctions-slides">
          <div className="swiper-container slider-mid items">
            <div className="swiper-wrapper">
              {cryptoBoys.map(() => (
                <div className="swiper-slide">
                  <div className="card">
                    *<br />
                    **
                    <br />
                    **
                    <br />
                    **
                    <br />
                    **
                    <br />*
                  </div>
                </div>
              ))}
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
              <div className="swiper-pagination"></div>
              <div className="swiper-scrollbar"></div>
            </div>
          </div>
        </div> */}
        <div className="row items">
          {cryptoBoys.map((cryptoboy, i) => (
            <>
              {i < 4 ? (
                <div
                  className="col-12 col-sm-6 col-lg-3 item"
                  key={cryptoboy.tokenId.toNumber()}
                >
                  <Link to={`nftdetail/${cryptoboy.tokenId}`}>
                    <div className="card cardAll">
                      <div className="image-over">
                        {/* <CryptoBoyNFTImage
                    colors={
                      cryptoboy.metaData !== undefined
                        ? cryptoboy.metaData.metaData.colors
                        : ""
                    }
                  /> */}
                        <img
                          src={`https://avatars.dicebear.com/api/avataaars/${cryptoboy.tokenId}.svg`}
                          alt=""
                        />
                      </div>
                      <div className="card-caption col-12 p-0 text-light">
                        <div className="card-body">
                          <h5 className="mb-0 text-light">
                            {cryptoboy.tokenName}
                          </h5>

                          <div className="seller text-light d-flex align-items-center my-3">
                            <span className="">Owned By</span>
                            <h6 className="ml-2 mb-0 text-light">
                              {cryptoboy.currentOwner.substr(0, 5) +
                                "..." +
                                cryptoboy.currentOwner.slice(
                                  cryptoboy.currentOwner.length - 5
                                )}
                            </h6>
                          </div>
                          <div className="card-bottom text-light d-flex justify-content-between">
                            <span>
                              {window.web3.utils.fromWei(
                                cryptoboy.price.toString(),
                                "Ether"
                              )}{" "}
                              Ξ
                            </span>
                            <span>1 of 1</span>
                          </div>
                          {/* <a
                    className="btn btn-bordered-white text-light font-weight-bold mt-4 py-2 px-3 text-capitalize btn-Home"
                    href="login.html"
                  >
                    <i className="fas fa-shopping-bag"></i> &nbsp;Place a Bid
                  </a> */}
                          <br />
                          <CryptoBoyNFTDetails cryptoboy={cryptoboy} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
        <div className="row mb-5">
          <div className="col-12 text-center">
            <Link
              to="/marketplace"
              className="btn btn-bordered-white btn-Home text-light mt-5"
            >
              View All
            </Link>
          </div>
        </div>
      </div>
      <div className="pt-4">
        {/* <div className="intro">
          <span
            className="font-weight-bold h5"
            style={{ fontWeight: "bolder" }}
          >
            HOW IT WORKS
          </span>
        </div> */}
        {/* <div>
          <h2 className="text-light text-capitalize my-3">
            Create and sell your NFTs
          </h2>
        </div>
        <div className="row mt-5 pb-5">
          <div className="col-12 col-sm-6 col-lg-3 item">
            <div className="">
              <i className="fas fa-wallet iconMain mb-4"></i>
              <br />
              <span className="h3 font-weight-bold text-light">
                Set up your wallet
              </span>
              <p className="mt-4">
                Once you’ve set up your wallet of choice, connect it to OpenSea
                by clicking the NFT Marketplace in the top right corner. Learn
                about the wallets we support.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 item">
            <i className="fas fa-wallet iconMain mb-4"></i> <br />
            <span className="h3 font-weight-bold text-light">
              Create your collection
            </span>
            <p className="mt-4">
              Click Create and set up your collection. Add social links, a
              description, profile & banner images, and set a secondary sales
              fee.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 item">
            <i className="fas fa-wallet iconMain mb-4"></i> <br />
            <span className="h3 font-weight-bold text-light">
              Add your NFTs
            </span>
            <p className="mt-4">
              Upload your work (image, video, audio, or 3D art), add a title and
              description, and customize your NFTs with properties, stats, and
              unlockable content.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 item">
            <i className="fas fa-wallet iconMain mb-4"></i> <br />
            <span className="h3 font-weight-bold text-light">
              List them for sale
            </span>
            <p className="mt-4">
              Choose between auctions, fixed-price listings, and declining-price
              listings. You choose how you want to sell your NFTs!
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AccountDetails;
