import React, { Component } from "react";
import CryptoBoyNFTImage from "../CryptoBoyNFTImage/CryptoBoyNFTImage";
import place from "./place.jpg";

class FormAndPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelectedColors: [
        {
          cardBorderColor: "#a5d996",
          cardBackgroundColor: "#85b120",
          headBorderColor: "#a5d996",
          headBackgroundColor: "#da8181",
          leftEyeBorderColor: "#a5d996",
          rightEyeBorderColor: "#a5d996",
          leftEyeBackgroundColor: "#85b120",
          rightEyeBackgroundColor: "#da8181",
          leftPupilBackgroundColor: "#a5d996",
          rightPupilBackgroundColor: "#333257",
          mouthColor: "#da8181",
          neckBackgroundColor: "#8ee6d5",
          neckBorderColor: "#a5d996",
          bodyBackgroundColor: "#8ee6d5",
          bodyBorderColor: "#333257",
        },
      ],
      cryptoBoyName: "",
      cryptoBoyPrice: "",
    };
  }

  componentDidMount = async () => {
    await this.props.setMintBtnTimer();
  };

  callMintMyNFTFromApp = (e) => {
    e.preventDefault();
    console.log("1st step");
    this.props.mintMyNFT(
      this.state.userSelectedColors[0],
      this.state.cryptoBoyName,
      this.state.cryptoBoyPrice
    );
  };
  shuffleImg = () => {
    this.setState({ randomInt: Math.floor(Math.random() * 10) + 1 });
  };
  render() {
    return (
      <div className="py-5">
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-md-4">
            <div className="card no-hover text-center mainCard rounded">
              <div className="image-over">
                <img
                  className="card-img-top"
                  src={`https://avatars.dicebear.com/api/avataaars/${this.state.randomInt}.svg`}
                  alt=""
                />
              </div>
              <div className="my-2">
                <button
                  className="btn w-75 mt-3 mt-sm-4 text-light rounded-pill btn-Main"
                  onClick={this.shuffleImg}
                >
                  Shuffle Image
                </button>
              </div>
              <div className="card-caption col-12 p-0">
                <div className="card-body">
                  <h5 className="mb-3 text-light">Artnox</h5>
                  <p className="my-3">Description goes here.</p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ZqpthncaYTsd0579hasfu00st"
                    />
                    <div
                      className="input-group-append"
                      style={{ cursor: "pointer" }}
                    >
                      <button>
                        <i className="far fa-copy"></i>
                      </button>
                    </div>
                  </div>
                  <div className="social-icons d-flex justify-content-center my-3">
                    <a
                      className=""
                      href="https://www.facebook.com/webmobtechnologies"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className=""
                      href="https://www.instagram.com/webmobtech/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className=""
                      href="https://twitter.com/webmobtech"
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      className=""
                      href="https://www.linkedin.com/company/2014545"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                  <a
                    className="btn btn-bordered-white btn-Home text-light btn-smaller"
                    href="#"
                  >
                    Follow
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="mb-4">
              <div className="intro">
                <span className="font-weight-bold h5 iconMain">
                  GET STARTED
                </span>
              </div>
              <h2 className="text-light text-capitalize my-4">Create Item</h2>
              <form
                className="item-form  no-hover mainCard cardPadding rounded"
                onSubmit={this.callMintMyNFTFromApp}
                autocomplete="off"
              >
                <div className="row">
                  {/* <div className="col-12">
                    <div className="input-group form-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input inputField"
                          id="inputGroupFile01"
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          className="custom-file-label inputField"
                          for="inputGroupFile01"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control inputField"
                        name="name"
                        placeholder="Item Name"
                        required="required"
                        onChange={
                          ((e) =>
                            this.setState({ cryptoBoyName: e.target.value }),
                          this.shuffleImg)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control inputField"
                        name="price"
                        required="required"
                        value={this.state.cryptoBoyPrice}
                        placeholder="Enter Price In Ξ"
                        onChange={(e) =>
                          this.setState({ cryptoBoyPrice: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="w-100 mt-3 mt-sm-4 btn btn-Main text-light rounded-pill"
                      type="submit"
                    >
                      Create Item
                    </button>
                  </div>
                  <div className="mt-4 ml-auto mr-auto">
                    {this.props.nameIsUsed ? (
                      <div className="alert alert-danger  inputField alert-dissmissible text-light">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          className="inputField text-light border-0"
                        >
                          <span>&times;&nbsp;</span>
                        </button>
                        <strong className="text-light">
                          This name is taken!
                        </strong>
                      </div>
                    ) : this.props.colorIsUsed ? (
                      <>
                        <div className="alert alert-danger alert-dissmissible inputField">
                          <button
                            type="button"
                            className="close inputField text-light"
                            data-dismiss="alert"
                          >
                            <span>&times;&nbsp;</span>
                          </button>
                          {this.props.colorsUsed.length > 1 ? (
                            <strong className="text-light">
                              These colors are taken!
                            </strong>
                          ) : (
                            <strong className="text-light">
                              This color is taken!
                            </strong>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "1rem",
                            marginBottom: "3rem",
                          }}
                        >
                          {this.props.colorsUsed.map((color, index) => (
                            <div
                              key={index}
                              style={{
                                background: `${color}`,
                                width: "50%",
                                height: "50px",
                              }}
                            ></div>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      /* <div className="py-3">
        <div className="card mt-4">
          <div className="card-body align-items-center d-flex justify-content-center">
            <h5 className="text-light">
              Color Your Crypto Boy As You Want It To be!
            </h5>
          </div>
        </div>
        <form onSubmit={this.callMintMyNFTFromApp} className="pt-4 mt-1">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label className="text-light" htmlFor="cardBorderColor">
                  Card Border Color
                </label>
                <input
                  required
                  type="color"
                  name="cardBorderColor"
                  id="cardBorderColor"
                  value={this.state.userSelectedColors[0].cardBorderColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          cardBorderColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="cardBackgroundColor">
                  Card Background Color
                </label>
                <input
                  required
                  type="color"
                  name="cardBackgroundColor"
                  id="cardBackgroundColor"
                  value={this.state.userSelectedColors[0].cardBackgroundColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          cardBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="headBorderColor">
                  Head Border Color
                </label>
                <input
                  required
                  type="color"
                  name="headBorderColor"
                  id="headBorderColor"
                  value={this.state.userSelectedColors[0].headBorderColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          headBorderColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="headBackgroundColor">
                  Head Background Color
                </label>
                <input
                  required
                  type="color"
                  name="headBackgroundColor"
                  id="headBackgroundColor"
                  value={this.state.userSelectedColors[0].headBackgroundColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          headBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="text-light" htmlFor="leftEyeBorderColor">
                  Left Eye Border Color
                </label>
                <input
                  required
                  type="color"
                  name="leftEyeBorderColor"
                  id="leftEyeBorderColor"
                  value={this.state.userSelectedColors[0].leftEyeBorderColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          leftEyeBorderColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="rightEyeBorderColor">
                  Right Eye Border Color
                </label>
                <input
                  required
                  type="color"
                  name="rightEyeBorderColor"
                  id="rightEyeBorderColor"
                  value={this.state.userSelectedColors[0].rightEyeBorderColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          rightEyeBorderColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="leftEyeBackgroundColor">
                  Left Eye Background Color
                </label>
                <input
                  required
                  type="color"
                  name="leftEyeBackgroundColor"
                  id="leftEyeBackgroundColor"
                  value={
                    this.state.userSelectedColors[0].leftEyeBackgroundColor
                  }
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          leftEyeBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="rightEyeBackgroundColor">
                  Right Eye Background Color
                </label>
                <input
                  required
                  type="color"
                  name="rightEyeBackgroundColor"
                  id="rightEyeBackgroundColor"
                  value={
                    this.state.userSelectedColors[0].rightEyeBackgroundColor
                  }
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          rightEyeBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <CryptoBoyNFTImage colors={this.state.userSelectedColors[0]} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  className="text-light"
                  htmlFor="leftPupilBackgroundColor"
                >
                  Left Pupil Background Color
                </label>
                <input
                  required
                  type="color"
                  name="leftPupilBackgroundColor"
                  id="leftPupilBackgroundColor"
                  value={
                    this.state.userSelectedColors[0].leftPupilBackgroundColor
                  }
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          leftPupilBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label
                  className="text-light"
                  htmlFor="rightPupilBackgroundColor"
                >
                  Right Pupil Background Color
                </label>
                <input
                  required
                  type="color"
                  name="rightPupilBackgroundColor"
                  id="rightPupilBackgroundColor"
                  value={
                    this.state.userSelectedColors[0].rightPupilBackgroundColor
                  }
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          rightPupilBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="mouthColor">
                  Mouth Color
                </label>
                <input
                  required
                  type="color"
                  name="mouthColor"
                  id="mouthColor"
                  value={this.state.userSelectedColors[0].mouthColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          mouthColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="neckBackgroundColor">
                  Neck Background Color
                </label>
                <input
                  required
                  type="color"
                  name="neckBackgroundColor"
                  id="neckBackgroundColor"
                  value={this.state.userSelectedColors[0].neckBackgroundColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          neckBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="text-light" htmlFor="neckBorderColor">
                  Neck Border Color
                </label>
                <input
                  required
                  type="color"
                  name="neckBorderColor"
                  id="neckBorderColor"
                  value={this.state.userSelectedColors[0].neckBorderColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          neckBorderColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="bodyBackgroundColor">
                  Body Background Color
                </label>
                <input
                  required
                  type="color"
                  name="bodyBackgroundColor"
                  id="bodyBackgroundColor"
                  value={this.state.userSelectedColors[0].bodyBackgroundColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          bodyBackgroundColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="text-light" htmlFor="bodyBorderColor">
                  Body Border Color
                </label>
                <input
                  required
                  type="color"
                  name="bodyBorderColor"
                  id="bodyBorderColor"
                  value={this.state.userSelectedColors[0].bodyBorderColor}
                  className="form-control inputColor"
                  onChange={(e) =>
                    this.setState({
                      userSelectedColors: [
                        {
                          ...this.state.userSelectedColors[0],
                          bodyBorderColor: e.target.value,
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="text-light" htmlFor="cryptoBoyName">
                  Name
                </label>
                <input
                  required
                  type="text"
                  value={this.state.cryptoBoyName}
                  className="form-control inputColor"
                  placeholder="Enter Your Crypto Boy's Name"
                  onChange={(e) =>
                    this.setState({ cryptoBoyName: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="text-light" htmlFor="price">
                  Price
                </label>
                <input
                  required
                  type="number"
                  name="price"
                  id="cryptoBoyPrice"
                  value={this.state.cryptoBoyPrice}
                  className="form-control inputColor"
                  placeholder="Enter Price In Ξ"
                  onChange={(e) =>
                    this.setState({ cryptoBoyPrice: e.target.value })
                  }
                />
              </div>
              <button
                id="mintBtn"
                style={{ fontSize: "0.9rem", letterSpacing: "0.14rem" }}
                type="submit"
                className="btn mt-4 btn-block btn-Main text-light"
              >
                Mint My Crypto Boy
              </button>
              <div className="mt-4">
                {this.props.nameIsUsed ? (
                  <div className="alert alert-danger alert-dissmissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                    >
                      <span>&times;</span>
                    </button>
                    <strong>This name is taken!</strong>
                  </div>
                ) : this.props.colorIsUsed ? (
                  <>
                    <div className="alert alert-danger alert-dissmissible">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                      >
                        <span>&times;</span>
                      </button>
                      {this.props.colorsUsed.length > 1 ? (
                        <strong>These colors are taken!</strong>
                      ) : (
                        <strong>This color is taken!</strong>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "1rem",
                        marginBottom: "3rem",
                      }}
                    >
                      {this.props.colorsUsed.map((color, index) => (
                        <div
                          key={index}
                          style={{
                            background: `${color}`,
                            width: "50%",
                            height: "50px",
                          }}
                        ></div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div> */
    );
  }
}

export default FormAndPreview;
