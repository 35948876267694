import React, { Component } from "react";
import { HashRouter, Route } from "react-router-dom";
import "./App.css";
import Web3 from "web3";
import CryptoBoys from "../abis/CryptoBoys.json";

import FormAndPreview from "../components/FormAndPreview/FormAndPreview";
import AllCryptoBoys from "./AllCryptoBoys/AllCryptoBoys";
import AccountDetails from "./AccountDetails/AccountDetails";
import ContractNotDeployed from "./ContractNotDeployed/ContractNotDeployed";
import ConnectToMetamask from "./ConnectMetamask/ConnectToMetamask";
import Loading from "./Loading/Loading";
import Navbar from "./Navbar/Navbar";
import MyCryptoBoys from "./MyCryptoBoys/MyCryptoBoys";
import Queries from "./Queries/Queries";
import Footer from "./Footer/Footer";
import { Container } from "react-bootstrap";
import Contact from "./Contact/Contact";
import CryptoNFTDetail from "./CryptoNFTDetail/CryptoNFTDetail";
import WalletConnect from "./WalletConnect/WalletConnect";
import ScrollToTop from "../ScrollTop";
import LoginPage from "./Login/LoginPage";

const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountAddress: "",
      accountBalance: "",
      cryptoBoysContract: null,
      cryptoBoysCount: 0,
      cryptoBoys: [],
      loading: true,
      metamaskConnected: false,
      contractDetected: false,
      totalTokensMinted: 0,
      totalTokensOwnedByAccount: 0,
      nameIsUsed: false,
      colorIsUsed: false,
      colorsUsed: [],
      lastMintTime: null,
    };
  }

  componentWillMount = async () => {
    await this.loadWeb3();
    await this.loadBlockchainData();
    await this.setMetaData();
    await this.setMintBtnTimer();
  };

  setMintBtnTimer = () => {
    const mintBtn = document.getElementById("mintBtn");
    if (mintBtn !== undefined && mintBtn !== null) {
      this.setState({
        lastMintTime: localStorage.getItem(this.state.accountAddress),
      });
      this.state.lastMintTime === undefined || this.state.lastMintTime === null
        ? (mintBtn.innerHTML = "Mint My Crypto Boy")
        : this.checkIfCanMint(parseInt(this.state.lastMintTime));
    }
  };

  checkIfCanMint = (lastMintTime) => {
    const mintBtn = document.getElementById("mintBtn");
    const timeGap = 300000; //5min in milliseconds
    const countDownTime = lastMintTime + timeGap;
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const diff = countDownTime - now;
      if (diff < 0) {
        mintBtn.removeAttribute("disabled");
        mintBtn.innerHTML = "Mint My Crypto Boy";
        localStorage.removeItem(this.state.accountAddress);
        clearInterval(interval);
      } else {
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        mintBtn.setAttribute("disabled", true);
        mintBtn.innerHTML = `Next mint in ${minutes}m ${seconds}s`;
      }
    }, 1000);
  };

  loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
      this.setState({ metamaskConnected: false });
    } else {
      this.setState({ metamaskConnected: true });
      this.setState({ loading: true });
      this.setState({ accountAddress: accounts[0] });
      let accountBalance = await web3.eth.getBalance(accounts[0]);
      accountBalance = web3.utils.fromWei(accountBalance, "Ether");
      this.setState({ accountBalance });
      this.setState({ loading: false });
      const networkId = await web3.eth.net.getId();

      console.log(CryptoBoys.networks);
      console.log(networkId);
      const networkData = CryptoBoys.networks[networkId];
      if (networkData) {
        this.setState({ loading: true });
        const cryptoBoysContract = web3.eth.Contract(
          CryptoBoys.abi,
          networkData.address
        );
        this.setState({ cryptoBoysContract });
        this.setState({ contractDetected: true });
        const cryptoBoysCount = await cryptoBoysContract.methods
          .cryptoBoyCounter()
          .call();
        this.setState({ cryptoBoysCount });
        for (var i = 1; i <= cryptoBoysCount; i++) {
          const cryptoBoy = await cryptoBoysContract.methods
            .allCryptoBoys(i)
            .call();
          this.setState({
            cryptoBoys: [...this.state.cryptoBoys, cryptoBoy],
          });
        }
        let totalTokensMinted = await cryptoBoysContract.methods
          .getNumberOfTokensMinted()
          .call();
        console.log(totalTokensMinted);

        if (totalTokensMinted) {
          totalTokensMinted = totalTokensMinted.toNumber();
        }

        this.setState({ totalTokensMinted });
        let totalTokensOwnedByAccount = await cryptoBoysContract.methods
          .getTotalNumberOfTokensOwnedByAnAddress(this.state.accountAddress)
          .call();

        if (totalTokensOwnedByAccount) {
          totalTokensOwnedByAccount = totalTokensOwnedByAccount.toNumber();
        }

        this.setState({ totalTokensOwnedByAccount });
        this.setState({ loading: false });
      } else {
        this.setState({ contractDetected: false });
      }
    }
  };
  totalTokensOwnedByAccount;
  connectToMetamask = async () => {
    await window.totalTokensOwnedByAccount.enable();
    this.setState({ metamaskConnected: true });
    window.location.reload();
  };

  setMetaData = async () => {
    if (this.state.cryptoBoys.length !== 0) {
      this.state.cryptoBoys.map(async (cryptoboy) => {
        const result = await fetch(cryptoboy.tokenURI);
        const metaData = await result.json();
        this.setState({
          cryptoBoys: this.state.cryptoBoys.map((cryptoboy) =>
            cryptoboy.tokenId.toNumber() === Number(metaData.tokenId)
              ? {
                  ...cryptoboy,
                  metaData,
                }
              : cryptoboy
          ),
        });
      });
    }
  };

  mintMyNFT = async (colors, name, tokenPrice) => {
    console.log("in app 1st");
    this.setState({ loading: true });
    const colorsArray = Object.values(colors);
    let colorsUsed = [];
    // for (let i = 0; i < colorsArray.length; i++) {
    //   if (colorsArray[i] !== "") {
    //     let colorIsUsed = await this.state.cryptoBoysContract.methods
    //       .colorExists(colorsArray[i])
    //       .call();
    //     console.log("color called");
    //     if (colorIsUsed) {
    //       colorsUsed = [...colorsUsed, colorsArray[i]];
    //       console.log("color2nd called");
    //     } else {
    //       continue;
    //       console.log("color 3rd called");
    //     }
    //   }
    // }
    const nameIsUsed = await this.state.cryptoBoysContract.methods
      .tokenNameExists(name)
      .call();
    console.log("color 4th called");
    // if (colorsUsed.length === 0 && !nameIsUsed) {
    if (true) {
      const {
        cardBorderColor,
        cardBackgroundColor,
        headBorderColor,
        headBackgroundColor,
        leftEyeBorderColor,
        rightEyeBorderColor,
        leftEyeBackgroundColor,
        rightEyeBackgroundColor,
        leftPupilBackgroundColor,
        rightPupilBackgroundColor,
        mouthColor,
        neckBackgroundColor,
        neckBorderColor,
        bodyBackgroundColor,
        bodyBorderColor,
      } = colors;
      let previousTokenId;
      previousTokenId = await this.state.cryptoBoysContract.methods
        .cryptoBoyCounter()
        .call();
      if (previousTokenId) {
        previousTokenId = previousTokenId.toNumber();
      }
      // previousTokenId = previousTokenId.toNumber();
      const tokenId = previousTokenId + 1;
      const tokenObject = {
        tokenName: "Crypto Boy",
        tokenSymbol: "CB",
        tokenId: `${tokenId}`,
        name: name,
        metaData: {
          type: "color",
          colors: {
            cardBorderColor,
            cardBackgroundColor,
            headBorderColor,
            headBackgroundColor,
            leftEyeBorderColor,
            rightEyeBorderColor,
            leftEyeBackgroundColor,
            rightEyeBackgroundColor,
            leftPupilBackgroundColor,
            rightPupilBackgroundColor,
            mouthColor,
            neckBackgroundColor,
            neckBorderColor,
            bodyBackgroundColor,
            bodyBorderColor,
          },
        },
      };
      const cid = await ipfs.add(JSON.stringify(tokenObject));
      let tokenURI = `https://ipfs.infura.io/ipfs/${cid.path}`;
      const price = window.web3.utils.toWei(tokenPrice.toString(), "Ether");
      this.state.cryptoBoysContract.methods
        .mintCryptoBoy(name, tokenURI, price, colorsArray)
        .send({ from: this.state.accountAddress })
        .on("confirmation", () => {
          localStorage.setItem(this.state.accountAddress, new Date().getTime());
          this.setState({ loading: false });
          console.log("confirmation");
          window.location.reload();
        });
    }
    // } else {
    //   if (nameIsUsed) {
    //     this.setState({ nameIsUsed: true });
    //     this.setState({ loading: false });
    //     console.log("name used called");
    //   } else if (colorsUsed.length !== 0) {
    //     this.setState({ colorIsUsed: true });
    //     this.setState({ colorsUsed });
    //     this.setState({ loading: false });
    //     console.log("length not zero");
    //   }
    // }
  };

  toggleForSale = (tokenId) => {
    this.setState({ loading: true });
    this.state.cryptoBoysContract.methods
      .toggleForSale(tokenId)
      .send({ from: this.state.accountAddress })
      .on("confirmation", () => {
        this.setState({ loading: false });
        window.location.reload();
      });
  };

  changeTokenPrice = (tokenId, newPrice) => {
    this.setState({ loading: true });
    const newTokenPrice = window.web3.utils.toWei(newPrice, "Ether");
    this.state.cryptoBoysContract.methods
      .changeTokenPrice(tokenId, newTokenPrice)
      .send({ from: this.state.accountAddress })
      .on("confirmation", () => {
        this.setState({ loading: false });
        window.location.reload();
      });
  };

  buyCryptoBoy = (tokenId, price) => {
    this.setState({ loading: true });
    this.state.cryptoBoysContract.methods
      .buyToken(tokenId)
      .send({ from: this.state.accountAddress, value: price })
      .on("confirmation", () => {
        this.setState({ loading: false });
        window.location.reload();
      });
  };

  render() {
    return (
      <div>
        {!this.state.metamaskConnected ? (
          <ConnectToMetamask connectToMetamask={this.connectToMetamask} />
        ) : !this.state.contractDetected ? (
          <ContractNotDeployed />
        ) : this.state.loading ? (
          <Loading />
        ) : (
          <>
            <HashRouter basename="/">
              <ScrollToTop>
                <Navbar />
                <div
                  style={{ backgroundColor: "#09080D", minHeight: "30vh" }}
                  className="pt-2"
                >
                  <Container>
                    <Route
                      path="/"
                      exact
                      render={() => (
                        <AccountDetails
                          accountAddress={this.state.accountAddress}
                          cryptoBoys={this.state.cryptoBoys}
                          accountBalance={this.state.accountBalance}
                        />
                      )}
                    />
                    <Route
                      path="/mint"
                      render={() => (
                        <FormAndPreview
                          mintMyNFT={this.mintMyNFT}
                          nameIsUsed={this.state.nameIsUsed}
                          colorIsUsed={this.state.colorIsUsed}
                          colorsUsed={this.state.colorsUsed}
                          setMintBtnTimer={this.setMintBtnTimer}
                        />
                      )}
                    />
                    <Route
                      path="/marketplace"
                      render={() => (
                        <AllCryptoBoys
                          accountAddress={this.state.accountAddress}
                          cryptoBoys={this.state.cryptoBoys}
                          totalTokensMinted={this.state.totalTokensMinted}
                          changeTokenPrice={this.changeTokenPrice}
                          toggleForSale={this.toggleForSale}
                          buyCryptoBoy={this.buyCryptoBoy}
                        />
                      )}
                    />
                    {console.log(this.state.cryptoBoys)}
                    <Route
                      path="/my-tokens"
                      render={() => (
                        <MyCryptoBoys
                          accountAddress={this.state.accountAddress}
                          cryptoBoys={this.state.cryptoBoys}
                          totalTokensOwnedByAccount={
                            this.state.totalTokensOwnedByAccount
                          }
                        />
                      )}
                    />
                    <Route
                      path="/nftdetail/:id"
                      render={() => (
                        <CryptoNFTDetail
                          cryptoboys={this.state.cryptoBoys}
                          totalTokensMinted={this.state.totalTokensMinted}
                          changeTokenPrice={this.changeTokenPrice}
                          toggleForSale={this.toggleForSale}
                          buyCryptoBoy={this.buyCryptoBoy}
                        />
                      )}
                    />
                    <Route
                      path="/queries"
                      render={() => (
                        <Queries
                          cryptoBoysContract={this.state.cryptoBoysContract}
                        />
                      )}
                    />
                    <Route path="/contact" render={() => <Contact />} />
                    <Route path="/wallet" render={() => <WalletConnect />} />
                    <Route path="/login" render={() => <LoginPage />} />
                  </Container>
                </div>
                <footer>
                  <Footer />
                </footer>
              </ScrollToTop>
            </HashRouter>
          </>
        )}
      </div>
    );
  }
}

export default App;
