import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import CryptoBoyNFTDetails from "../CryptoBoyNFTDetails/CryptoBoyNFTDetails";

const CryptoNFTDetail = ({
  cryptoboys,
  buyCryptoBoy,
  toggleForSale,
  changeTokenPrice,
  accountAddress,
}) => {
  const loc = useLocation();
  const [current, setCurrent] = React.useState(window.location.hash.slice(12));
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setCurrent(window.location.hash.slice(12));
    setData(
      cryptoboys.filter((item) => {
        if (item && item.tokenId._hex) {
          return parseInt(item.tokenId._hex) == window.location.hash.slice(12);
        }
        return false;
      })
    );
  }, []);
  console.log(data);
  return (
    <>
      {data &&
        data.map((i) => {
          return (
            <>
              <div className="row justify-content-between py-5">
                <div className="col-12 col-lg-5">
                  <div className="item-info">
                    <div className="item-thumb text-center">
                      <img
                        src={`https://avatars.dicebear.com/api/avataaars/${current}.svg`}
                        alt=""
                      />
                    </div>
                    <ul className="netstorm-tab nav nav-tabs py-3" id="nav-tab">
                      <li>
                        <a
                          className="active"
                          id="nav-home-tab"
                          data-toggle="pill"
                          href="#nav-home"
                        >
                          <span className="mx-2 h5 text-white">Bids</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="nav-profile-tab"
                          data-toggle="pill"
                          href="#nav-profile"
                        >
                          <span className="mx-2 h5 text-white">History</span>
                        </a>
                      </li>
                      <li>
                        <a
                          id="nav-contact-tab"
                          data-toggle="pill"
                          href="#nav-contact"
                        >
                          <span className="mx-2 h5 text-white">Details</span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active text-light"
                        id="nav-home"
                      >
                        <ul className="list-unstyled">
                          <li className="single-tab-list d-flex align-items-center">
                            <img
                              className="avatar-sm rounded-circle mr-3"
                              src={`https://i.pravatar.cc/150?u=${current}`}
                              alt=""
                            />
                            <p className="m-0">
                              Bid listed for <strong>14 ETH</strong> 4 hours ago{" "}
                              <br />
                              by{" "}
                              <a href="#" className="text-light">
                                @arham
                              </a>
                            </p>
                          </li>
                          <li className="single-tab-list d-flex align-items-center">
                            <img
                              className="avatar-sm rounded-circle mr-3"
                              src={`https://i.pravatar.cc/150?u=${current + 1}`}
                              alt=""
                            />
                            <p className="m-0">
                              Bid listed for <strong>10 ETH</strong> 8 hours ago{" "}
                              <br />
                              by{" "}
                              <a href="#" className="text-light">
                                @junaid
                              </a>
                            </p>
                          </li>
                          <li className="single-tab-list d-flex align-items-center">
                            <img
                              className="avatar-sm rounded-circle mr-3"
                              src={`https://i.pravatar.cc/150?u=${current + 2}`}
                              alt=""
                            />
                            <p className="m-0 text-light">
                              Bid listed for <strong>12 ETH</strong> 3 hours ago{" "}
                              <br />
                              by{" "}
                              <a href="#" className="text-light">
                                @yasmin
                              </a>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane fade" id="nav-profile">
                        <ul className="list-unstyled">
                          <li className="single-tab-list d-flex align-items-center">
                            <img
                              className="avatar-sm rounded-circle mr-3"
                              src={`https://i.pravatar.cc/150?u=${current}`}
                              alt=""
                            />
                            <p className="m-0 text-light">
                              Bid listed for <strong>32 ETH</strong> 10 hours
                              ago <br />
                              by{" "}
                              <a href="#" className="text-light">
                                @hasan
                              </a>
                            </p>
                          </li>
                          <li className="single-tab-list d-flex align-items-center ">
                            <img
                              className="avatar-sm rounded-circle mr-3"
                              src={`https://i.pravatar.cc/150?u=${current + 1}`}
                              alt=""
                            />
                            <p className="m-0 text-light">
                              Bid listed for <strong>24 ETH</strong> 6 hours ago{" "}
                              <br />
                              by{" "}
                              <a href="#" className="text-light">
                                @artnox
                              </a>
                            </p>
                          </li>
                          <li className="single-tab-list d-flex align-items-center">
                            <img
                              className="avatar-sm rounded-circle mr-3"
                              src={`https://i.pravatar.cc/150?u=${current + 2}`}
                              alt=""
                            />
                            <p className="m-0 text-light">
                              Bid listed for <strong>29 ETH</strong> 12 hours
                              ago <br />
                              by{" "}
                              <a href="#" className="text-light">
                                @meez
                              </a>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane fade" id="nav-contact">
                        <div className="owner-meta d-flex align-items-center mt-3 text-light">
                          <span>Owner</span>
                          <a
                            className="owner d-flex align-items-center ml-2"
                            href="#"
                          >
                            <img
                              className="avatar-sm rounded-circle"
                              src={`https://i.pravatar.cc/150?u=${current}`}
                              alt=""
                            />
                            <span className="ml-2 h5 text-light">
                              Themeland
                            </span>
                          </a>
                        </div>
                        <p className="mt-2 text-light">Created : 15 Jul 2021</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="content mt-5 mt-lg-0 py-5">
                    <h3 className="mb-4 text-light">
                      {i.tokenName ? i.tokenName : "Not available"}
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Laborum obcaecati dignissimos quae quo ad iste ipsum
                      officiis deleniti asperiores sit.
                    </p>

                    <div className="owner d-flex align-items-center">
                      <span>Owned By</span>
                      <a
                        className="owner-meta d-flex align-items-center ml-3"
                        href="#"
                      >
                        <img
                          className="avatar-sm rounded-circle"
                          src={`https://i.pravatar.cc/150?u=${current}`}
                          alt=""
                        />
                        <span className="ml-2 h5 text-light">Themeland</span>
                      </a>
                    </div>
                    <div className="item-info-list my-4 text-light">
                      <ul className="list-unstyled">
                        <li className="price d-flex justify-content-between">
                          <span>Current Price 1.5 ETH</span>
                          <span>$500.89</span>
                          <span>1 of 5</span>
                        </li>
                        <li>
                          <span>Size&nbsp;</span>
                          <span>14000 x 14000 px</span>
                        </li>
                        <li>
                          <span>Volume Traded</span>
                          <span>64.1</span>
                        </li>
                      </ul>
                    </div>
                    <div className="row items">
                      <div className="col-12 col-md-6 item px-lg-2">
                        <div className="card no-hover">
                          <div className="single-seller d-flex align-items-center">
                            <a href="#">
                              <img
                                className="avatar-md rounded-circle"
                                src={`https://i.pravatar.cc/150?u=${current}`}
                                alt=""
                              />
                            </a>
                            <div className="seller-info ml-3 text-light">
                              <a className="seller mb-2 text-light" href="#">
                                @ArtNoxStudio
                              </a>
                              <span className="d-block mt-2">Creator</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 item px-lg-2">
                        <div className="card no-hover">
                          <div className="single-seller d-flex align-items-center">
                            <a href="#">
                              <img
                                className="avatar-md rounded-circle"
                                src={`https://i.pravatar.cc/150?u=${current +
                                  1}`}
                                alt=""
                              />
                            </a>
                            <div className="seller-info ml-3 text-light">
                              <a
                                className="seller mb-2 text-light"
                                href="explore-2.html"
                              >
                                Virtual Worlds
                              </a>
                              <span className="d-block mt-2">Collection</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 item">
                        <div className="card no-hover p-3 text-light">
                          <span className="mt-0 mb-2 d-block h3 text-light">
                            Highest Bid
                          </span>
                          <div className="price d-flex justify-content-between align-items-center">
                            <span>2.9 BNB</span>
                            <span>1 of 5</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CryptoBoyNFTDetails
                      cryptoboy={i}
                      accountAddress={accountAddress}
                      changeTokenPrice={changeTokenPrice}
                      toggleForSale={toggleForSale}
                      buyCryptoBoy={buyCryptoBoy}
                    />
                    {/* <button
                      value={i.price}
                      disabled={!i.forSale}
                      className="d-block btn btn-bordered-white btn-Home text-light placeBtn"
                      to="/wallet"
                    >
                      Place a Bid
                    </button> */}
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default CryptoNFTDetail;
