import React from "react";
const Footer = () => {
  return (
    <div
      className="footer-area text-white pt-5 pb-2"
      style={{
        backgroundColor: "#121117",
      }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-sm-6 col-lg-3 res-margin">
              <div className="footer-items">
                <a
                  className="navbar-brand"
                  href="https://webmobtech.com"
                  target="_blank"
                >
                  <img
                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/02/WMT_Horizontal_White.png"
                    alt=""
                    width="200px"
                    height="auto"
                  />
                </a>
                <p className="mt-3">
                  We build your ideas blended with latest technologies,
                  innovation & finest quality
                </p>

                <div className="social-icons d-flex">
                  <a
                    className=""
                    href="https://www.facebook.com/webmobtechnologies"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className=""
                    href="https://www.instagram.com/webmobtech/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className=""
                    href="https://twitter.com/webmobtech"
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    className=""
                    href="https://www.linkedin.com/company/2014545"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    className=""
                    href="https://github.com/webmobtechnologies"
                    target="_blank"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-6 col-lg-3 res-margin">
              <div className="footer-items">
                <h4 className="footer-title">TOOLS & TECH</h4>
                <ul>
                  <li>
                    <a href="#" target="_blank">Mobile App Development</a>
                  </li>
                  <li>
                    <a href="#" target="_blank">Web Design & Development</a>
                  </li>
                  <li>
                    <a href="#" target="_blank">Android App Development Services</a>
                  </li>
                  <li>
                    <a href="#" target="_blank">iOS App Development Services</a>
                  </li>
                  <li>
                    <a href="#" target="_blank">Laravel Development Services</a>
                  </li>
                  <li>
                    <a href="#" target="_blank">React JS Development Services</a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-12 col-sm-6 col-lg-3 res-margin">
              <div className="footer-items">
                <a href="https://webmobtech.com/portfolio/" target="_blank">
                  <h4 className="footer-title">WORK</h4>
                </a>
                <ul>
                  <li>
                    <a
                      href="https://webmobtech.com/casestudy/web-and-app-development-like-zillow-for-real-estate/"
                      target="_blank"
                    >
                      HouzQuest
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/casestudy/on-demand-video-streaming-mobile-app-case-study/"
                      target="_blank"
                    >
                      Video Streaming App
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/casestudy/online-pathology-healthcare-mobile-app/"
                      target="_blank"
                    >
                      Sterling Accuris
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/casestudy/digital-business-card-app/"
                      target="_blank"
                    >
                      Switchit
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/casestudy/on-demand-courier-delivery-app-development-like-uber/"
                      target="_blank"
                    >
                      Zroomm
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 res-margin">
              <div className="footer-items">
                <a href="https://webmobtech.com/about-us/" target="_blank">
                  <h4 className="footer-title">COMPANY</h4>
                </a>
                <ul>
                  <li>
                    <a href="https://webmobtech.com/about-us/" target="_blank">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="https://webmobtech.com/career/" target="_blank">
                      Career
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/contact-us/"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="https://webmobtech.com/blog" target="_blank">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 res-margin">
              <div className="footer-items">
                <a href="https://webmobtech.com/services/" target="_blank">
                  <h4 className="footer-title">SERVICES</h4>
                </a>
                <ul>
                  <li>
                    <a
                      href="https://webmobtech.com/web-development-services/"
                      target="_blank"
                    >
                      Web Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/mobile-app-development-services/"
                      target="_blank"
                    >
                      Mobile Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/dedicated-development-services/"
                      target="_blank"
                    >
                      Hire Dedicated Developers
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/management-it-consulting-services/"
                      target="_blank"
                    >
                      Management & IT Consulting
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/product-design-development-services/"
                      target="_blank"
                    >
                      Product Design and Development Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webmobtech.com/devops-software-development-services/"
                      target="_blank"
                    >
                      DevOps
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                <h4 className="footer-title">Subscribe Us</h4>

                <div className="subscribe-form d-flex align-items-center">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="info@yourmail.com"
                  />
                  <button
                    type="submit"
                    className="btn-Main btn"
                    style={{ color: "white" }}
                  >
                    <i
                      className="far fa-paper-plane"
                      style={{ fontSize: "1rem" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright-area d-flex flex-wrap justify-content-center text-center py-4">
                <div className="copyright-left">
                  &copy;2021 WebMob Technologies. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
