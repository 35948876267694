import React from "react";
import metamaskIcon from "./metamask.svg";

const ConnectToMetamask = ({ connectToMetamask }) => {
  return (
    <div className="jumbotron JumbMain text-light ">
      <h1 className="display-5 text-light">Crypto Web WMT</h1>
      <p className="lead text-light">
        This is an NFT marketplace where you can mint your ERC721 implemented{" "}
        <i>Crypto Web WMT</i> and manage them.
      </p>
      <hr className="my-4 text-light" />
      <button
        onClick={connectToMetamask}
        className="btn btn-Main text-light d-flex align-items-center"
        style={{ fontSize: "0.9rem", letterSpacing: "0.14rem" }}
      >
        Connect metamask
        <img
          src={metamaskIcon}
          alt="metamask-icon"
          style={{ width: "2rem", marginLeft: "0.5rem" }}
        />
      </button>
    </div>
  );
};

export default ConnectToMetamask;
