import React from "react";
import { Link } from "react-router-dom";
import MetaMaskImg from "../../assets/WalletImages/metamask.png";
import authereum from "../../assets/WalletImages/authereum.png";
import dapper from "../../assets/WalletImages/dapper.png";
import kaikas from "../../assets/WalletImages/kaikas.png";
import walletconnect from "../../assets/WalletImages/walletconnect.png";
const WalletConnect = () => {
  return (
    <div className="py-4">
      <section className="wallet-connect-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              <div className="intro text-center text-light py-5">
                <span className="font-weight-bold h5 iconMain">
                  Wallet Connect
                </span>
                <h3 className="my-3 mb-0 text-light">Connect your Wallet</h3>
                <p className="text-light mt-2">Select wallet to continue</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center items">
            <div className="col-12 col-md-6 col-lg-4 item">
              <div className="card single-wallet">
                <Link className="d-block text-center" to="login">
                  <img className="avatar-lg" src={MetaMaskImg} alt="" />
                  <br />
                  <div className="my-3">
                    <span className="iconMain h5">MetaMask</span>
                    <p className="text-light mt-2">
                      A browser extension with great flexibility. The web's most
                      popular wallet
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 item">
              <div className="card single-wallet">
                <Link className="d-block text-center" to="login">
                  <img className="avatar-lg" src={authereum} alt="" />
                  <br />
                  <div className="my-3">
                    <span className="mb-0 iconMain h5">Authereum</span>
                    <p className="text-light mt-2">
                      A user-friendly wallet that allows you to sign up with
                      your phone number on any device
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 item">
              <div className="card single-wallet">
                <Link className="d-block text-center" to="login">
                  <img className="avatar-lg" src={walletconnect} alt="" />
                  <br />
                  <div className="my-3">
                    <span className="mb-0 iconMain h5">WalletConnect</span>
                    <p className="text-light mt-2">
                      Pair with Trust, Argent, MetaMask &amp; more. Works from
                      any browser, without an extension
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 item">
              <div className="card single-wallet">
                <Link className="d-block text-center" to="login">
                  <img className="avatar-lg" src={dapper} alt="" />
                  <br />
                  <div className="my-3">
                    <span className="mb-0 iconMain h5">Dapper</span>
                    <p className="text-light mt-2">
                      A security-focused cloud wallet with pin codes and
                      multi-factor authentication
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 item">
              <div className="card single-wallet">
                <Link className="d-block text-center" to="login">
                  <img className="avatar-lg" src={kaikas} alt="" />
                  <br />
                  <div className="my-3">
                    <span className="mb-0 iconMain h5 my-3">Kaikas</span>
                    <p className="text-light mt-2">
                      A simple-to-use wallet that works on both mobile and
                      through a browser extension
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WalletConnect;
