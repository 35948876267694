import React from "react";

const LoginPage = () => {
  return (
    <div>
      <section className="author-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              <div className="intro text-center text-light py-4">
                <span className="iconMain h4 ">Login</span>
                <h3 className="my-3 mb-0 text-light">Login to your Account</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Laborum obcaecati dignissimos quae quo ad iste ipsum officiis
                  deleniti asperiores sit.
                </p>
              </div>
              <form className="item-form card no-hover">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <input
                        type="email"
                        className="form-control inputField"
                        name="email"
                        placeholder="Enter your Email"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <input
                        type="password"
                        className="form-control inputField"
                        name="password"
                        placeholder="Enter your Password"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input inputField"
                          type="checkbox"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn w-100 mt-3 mt-sm-4 text-light rounded-pill btn-Main"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="col-12">
                    <hr />
                    <div className="other-option">
                      <span className="d-block text-center text-light mb-4">
                        Or
                      </span>
                      <div className="social-icons d-flex justify-content-center">
                        <a className="" href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="" href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a className="" href="#">
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
