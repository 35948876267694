import React, { useState, useEffect } from "react";
import CryptoBoyNFTImage from "../CryptoBoyNFTImage/CryptoBoyNFTImage";
import CryptoBoyNFTDetails from "../CryptoBoyNFTDetails/CryptoBoyNFTDetails";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
// import place from "./place";

const AllCryptoBoys = ({
  cryptoBoys,
  accountAddress,
  totalTokensMinted,
  changeTokenPrice,
  toggleForSale,
  buyCryptoBoy,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cryptoBoys.length !== 0) {
      if (cryptoBoys[0].metaData !== undefined) {
        setLoading(loading);
      } else {
        setLoading(false);
      }
    }
  }, [cryptoBoys]);
  return (
    <div className="py-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-7">
          <div className="intro text-center">
            <span className="font-weight-bold h5 iconMain">Explore</span>
            <h3 className="my-3 text-light">Exclusive Digital Assets</h3>
            <p>Explore on the world's first & largest NFT marketplace</p>
          </div>
        </div>
      </div>
      <div className="row items mt-4">
        {cryptoBoys.map((cryptoboy) => (
          <div
            className="col-12 col-sm-6 col-lg-3 item"
            key={cryptoboy.tokenId.toNumber()}
          >
            <Link to={`nftdetail/${cryptoboy.tokenId.toNumber()}`}>
              <div className="card cardAll">
                <div className="image-over">
                  {/* <CryptoBoyNFTImage
                    colors={
                      cryptoboy.metaData !== undefined
                        ? cryptoboy.metaData.metaData.colors
                        : ""
                    }
                  /> */}
                  <img
                    src={`https://avatars.dicebear.com/api/avataaars/${cryptoboy.tokenId}.svg`}
                    alt=""
                  />
                </div>
                <div className="card-caption col-12 p-0 text-light">
                  <div className="card-body">
                    <h5 className="mb-0 text-light">{cryptoboy.tokenName}</h5>

                    <div className="seller text-light d-flex align-items-center my-3">
                      <span className="">Owned By</span>
                      <h6 className="ml-2 mb-0 text-light">
                        {cryptoboy.currentOwner.substr(0, 5) +
                          "..." +
                          cryptoboy.currentOwner.slice(
                            cryptoboy.currentOwner.length - 5
                          )}
                      </h6>
                    </div>
                    <div className="card-bottom text-light d-flex justify-content-between">
                      <span>
                        {window.web3.utils.fromWei(
                          cryptoboy.price.toString(),
                          "Ether"
                        )}{" "}
                        Ξ
                      </span>
                      <span>1 of 1</span>
                    </div>
                    {/* <a
                    className="btn btn-bordered-white text-light font-weight-bold mt-4 py-2 px-3 text-capitalize btn-Home"
                    href="login.html"
                  >
                    <i className="fas fa-shopping-bag"></i> &nbsp;Place a Bid
                  </a> */}
                    <br />
                    <CryptoBoyNFTDetails
                      cryptoboy={cryptoboy}
                      accountAddress={accountAddress}
                      changeTokenPrice={changeTokenPrice}
                      toggleForSale={toggleForSale}
                      buyCryptoBoy={buyCryptoBoy}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* <div className="row mb-5">
        <div className="col-12 text-center">
          <a
            id="load-btn"
            className="btn btn-bordered-white btn-Home text-light mt-5"
            href="#"
          >
            Load More
          </a>
        </div>
      </div> */}
      {/* <div className="card mt-1">
        <div className="card-body align-items-center d-flex justify-content-center bg-dark ">
          <h5 className="text-light">
            Total No. of CryptoBoy's Minted On The Platform :{" "}
            {totalTokensMinted}
          </h5>
        </div>
      </div>
      <div className="d-flex flex-wrap mb-2">
        {cryptoBoys.map((cryptoboy) => {
          return (
            <div
              key={cryptoboy.tokenId.toNumber()}
              className="w-50 p-4 mt-1 border"
            >
              {!loading ? (
                <CryptoBoyNFTImage
                  colors={
                    cryptoboy.metaData !== undefined
                      ? cryptoboy.metaData.metaData.colors
                      : ""
                  }
                />
              ) : (
                <Loading />
              )}
              <CryptoBoyNFTDetails
                cryptoboy={cryptoboy}
                accountAddress={accountAddress}
                changeTokenPrice={changeTokenPrice}
                toggleForSale={toggleForSale}
                buyCryptoBoy={buyCryptoBoy}
              />
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default AllCryptoBoys;
