import React from "react";
import "./style.css";

const Contact = () => {
  return (
    <>
      <section className="author-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              <div className="intro text-center my-5">
                <span className="h4">Contact</span>
                <h3 className="mt-3 mb-3 text-capitalize text-muted">
                  Get In Touch
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Laborum obcaecati dignissimos quae quo ad iste ipsum officiis
                  deleniti asperiores sit.
                </p>
              </div>

              <form
                id="contact-form"
                className="item-form card no-hover rounded"
                method="POST"
                style={{ backgroundColor: "#111111", marginBottom: "100px" }}
                action="assets/php/mail.php"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control inputField"
                        name="name"
                        placeholder="Name"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <input
                        type="email"
                        className="form-control inputField"
                        name="email"
                        placeholder="Email"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control inputField"
                        name="subject"
                        placeholder="Subject"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control inputField"
                        name="message"
                        placeholder="Message"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-Main w-100 text-light text-capitalize rounded-pill mt-3 mt-sm-4"
                      type="submit"
                    >
                      <i
                        className="far fa-paper-plane"
                        style={{ color: "white" }}
                      ></i>
                      &nbsp;&nbsp; Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
